import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import Menu from '../components/navbar/Menu';
import Footer from '../components/footer';
import PostCard from '../components/postcard';

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fields: { draft: { eq: false } } } # add
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              slug
              description
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const postsByDate = data.allMdx.edges.sort(
    (a, b) => moment(b.node.frontmatter.date) - moment(a.node.frontmatter.date),
  );
  const firstPost = postsByDate[0];

  console.log(postsByDate);

  return (
    <div className="flex w-screen overflow-x-hidden">
      <SEO
        title="KODAI APP | Blog"
        // image={ogImage}
        description="We cover songwriting and AI."
        url="/blog"
      />
      <div className="flex-col flex items-center">
        <Menu blog home={false} />
        <div className="flex h-auto w-screen bg-salmon sm:px-10 md:px-24 lg:px-24 xl:px-24 sm:pt-20 sm:pb-16 md:pt-0 md:pb-24 lg:pt-0 lg:pb-24 xl:pt-0 xl:pb-24">
          {firstPost && (
            <div className="sm:hidden lg:block flex flex-row mt-32 mb-16 w-screen">
              <div className="sm:w-full md:w-3/4 lg:w-3/4 xl:w-3/4 sm:pr-0 md:pr-12 lg:pr-12 xl:pr-12">
                <Img
                  fluid={firstPost.node.frontmatter.image.childImageSharp.fluid}
                  className="w-full newsletter"
                />
              </div>
              <div className="Post flex flex-col sm:w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
                <div className="font-display text-4xl leading- uppercase">
                  {firstPost.node.frontmatter.title}
                </div>
                <div className="font-alternate text-lg py-8">
                  {firstPost.node.frontmatter.date}
                </div>
                <div className="font-alternate text-lg pb-8">
                  {firstPost.node.frontmatter.description}
                </div>
                <div
                  // onClick={() => console.log('szia!')}
                  className="ReadMore cursor-pointer border border-black font-alternate"
                >
                  <Link to={`/blog/${firstPost.node.frontmatter.slug}`}>
                    {' '}
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="sm:block md:block lg:hidden xl:hidden pb-16">
            {firstPost && (
              <Link
                className="block w-full"
                to={`/blog/${firstPost.node.frontmatter.slug}`}
              >
                <PostCard
                  first
                  img={firstPost.node.frontmatter.image.childImageSharp.fluid}
                  title={firstPost.node.frontmatter.title}
                  subtitle={firstPost.node.frontmatter.date}
                  text={firstPost.node.frontmatter.description}
                  moreAction={() => console.log('clicked!')}
                />
              </Link>
            )}
          </div>
        </div>
        <div className="bg-white">
          <div className="sm:px-10 md:px-10 lg:px-24 xl:px-24">
            {postsByDate.filter(
              item =>
                item.node.frontmatter.slug !== firstPost.node.frontmatter.slug,
            ).length !== 0 && (
              <div className="font-display text-grey uppercase py-4 mt-6">
                Latest posts
              </div>
            )}
            <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {postsByDate
                .filter(
                  item =>
                    item.node.frontmatter.slug !==
                    firstPost.node.frontmatter.slug,
                )
                .map(edge => {
                  return (
                    <Link
                      className="block w-full"
                      to={edge.node.frontmatter.slug}
                    >
                      <PostCard
                        img={edge.node.frontmatter.image.childImageSharp.fluid}
                        title={edge.node.frontmatter.title}
                        subtitle={edge.node.frontmatter.date}
                        text={edge.node.frontmatter.description}
                        // moreAction={() => console.log('clicked!')}
                      />
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
